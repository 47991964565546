<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      :urls="urls"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import ActionsDialog from "@/own/components/settings/mailtemplate/ActionsDialog.vue";
// import EditItem from "@/own/components/settings/mailtemplate/EditItem";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/mailtemplates.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "Accounts",
  components: { DataTable },
  data: () => ({
    componentForEditing: ActionsDialog,
    componentForAdding: ActionsDialog,
    delete_url: `/settings/mailTemplate/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getMAILTEMPLATESTableFilters",
      getTableState: "getMAILTEMPLATESTableState",
      getTableData: "getMAILTEMPLATESTableData",
      getTableHeaders: "getMAILTEMPLATESTableHeaders",
      getTableProperties: "getMAILTEMPLATESTableProperties",
      getTableExportUrl: "getMAILTEMPLATESExportUrl",
      getTablePermissions: "getMAILTEMPLATESTablePermissions",
    },
    urls: {
      delete: `/settings/mailTemplate/destroy`,
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>

<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="pa-3 pb-0">
          <template v-if="!$store.getters.getMAILTEMPLATESIsCustomer">
            <v-autocomplete
              v-model="formData.type"
              label="Type"
              :items="serverData?.types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="typeErrors"
              @blur="$v.formData.type.$touch()"
            />

            <v-autocomplete
              v-if="formData.type === 3"
              v-model="formData.customer_id"
              label="Customer"
              :items="serverData?.customers"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="customer_idErrors"
              @blur="$v.formData.customer_id.$touch()"
            />
          </template>

          <v-text-field
            v-model="formData.name"
            :counter="40"
            label="Name"
            dense
            outlined
            clearable
            :error-messages="nameErrors"
            @blur="$v.formData.name.$touch()"
          />

          <div class="mt-3">
            <p class="font-size-h5 mb-0 px-2">
              Press the fields below to customize your mail template
            </p>
            <div class="w-100 row pb-4 pt-0 px-4 mt-1 mb-0" v-if="serverData">
              <div
                class="px-1 py-1"
                v-for="variable in serverData?.fields"
                :key="variable.index"
              >
                <v-chip
                  link
                  outlined
                  @click="() => insertSelectedText(variable.index)"
                  >{{ variable.text }}</v-chip
                >
              </div>
            </div>
          </div>

          <ckeditor
            ref="ckeditor"
            v-model="formData.mail"
            :config="editorConfig"
          />
        </div>
        <!--end::Body-->
        <!--begin::Actions-->
        <div class="my-8 d-flex flex-grow-1 align-end justify-center">
          <button class="btn btn-light mr-3 px-5 py-3 ls1" @click="resetData">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            {{
              $store.getters.getMAILTEMPLATESIsCustomer && actionType
                ? "Save as"
                : "Submit"
            }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      type: {
        required: requiredIf(function () {
          return !this.$store.getters.getMAILTEMPLATESIsCustomer;
        }),
      },
      customer_id: {
        required: requiredIf(function () {
          return (
            !this.$store.getters.getMAILTEMPLATESIsCustomer &&
            this.formData.type === 3
          );
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    originalTemplateName: null,
    serverData: null,
    formData: {
      type: null,
      name: null,
      mail: null,
      customer_id: null,
    },
    editorConfig: {
      // The configuration of the editor.
      versionCheck: false,
      toolbar: [
        {
          name: "document",
          items: [
            "Source",
            "-",
            "Preview",
            "Print",
            "-",
            // "Templates"
          ],
        },
        {
          name: "clipboard",
          items: [
            "Cut",
            "Copy",
            "Paste",
            "PasteText",
            "PasteFromWord",
            "-",
            "Undo",
            "Redo",
          ],
        },
        {
          name: "editing",
          items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
        },
        {
          name: "forms",
          items: [
            // "Form",
            // "Checkbox",
            // "Radio",
            // "TextField",
            // "Textarea",
            // "Select",
            "Button",
            "ImageButton",
            "HiddenField",
          ],
        },
        {
          name: "basicstyles",
          items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "Subscript",
            "Superscript",
            "-",
            "RemoveFormat",
          ],
        },
        {
          name: "paragraph",
          items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "Blockquote",
            "CreateDiv",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
            "-",
            "BidiLtr",
            "BidiRtl",
            // "Language",
          ],
        },
        // { name: "links", items: ["Link", "Unlink", "Anchor"] },
        {
          name: "insert",
          items: [
            "Image",
            "Table",
            "HorizontalRule",
            "Smiley",
            "SpecialChar",
            "PageBreak",
            // "Iframe",
          ],
        },
        { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
        { name: "colors", items: ["TextColor", "BGColor"] },
        { name: "tools", items: ["Maximize", "ShowBlocks"] },
        {
          name: "about",
          items: ["About", "DocProps", "CreatePlaceholder"],
        },
      ],
      fullPage: true,
      allowedContent: true,
      height: 300,
      extraPlugins: [
        "docprops",
        "placeholder",
        "about",
        "a11yhelp",
        "basicstyles",
        "bidi",
        "blockquote",
        "clipboard",
        "colorbutton",
        "colordialog",
        "contextmenu",
        "copyformatting",
        "dialogadvtab",
        "div",
        "elementspath",
        "enterkey",
        "entities",
        "exportpdf",
        "filebrowser",
        "find",
        "floatingspace",
        "font",
        "format",
        "forms",
        "horizontalrule",
        "htmlwriter",
        "iframe",
        "image",
        "indentblock",
        "indentlist",
        "justify",
        "language",
        "link",
        "list",
        "liststyle",
        "magicline",
        "maximize",
        "newpage",
        "pagebreak",
        "pastefromword",
        "pastetext",
        "preview",
        "print",
        "removeformat",
        "resize",
        "save",
        "scayt",
        "selectall",
        "showblocks",
        "showborders",
        "smiley",
        "sourcearea",
        "specialchar",
        "stylescombo",
        "tab",
        "table",
        "tabletools",
        "templates",
        "toolbar",
        "undo",
        "wysiwygarea",
        "tableresize",
        // "imageresize",
        "image2,uploadimage",
        // 'easyimage',
        "colordialog,tableresize",
      ],
      resize_enable: true,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    insertSelectedText(data) {
      const editor = this.$refs.ckeditor.instance;
      if (editor && data) {
        editor.insertHtml(`${data}`);
        editor.focus();
      }
    },
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/settings/mailTemplate/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = {
              ...data.template,
            };
            this.originalTemplateName = data.template.name;

            this.dialog = true;
          })
          .catch(() => {
            this.dialog = false;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        if (!this.serverData) {
          this.pageLoader(true);
          ApiService.post("/settings/mailTemplate/create")
            .then(({ data }) => {
              this.serverData = data;
              this.pageLoader(false);
            })
            .catch(() => {
              this.dialog = false;
              this.pageLoader(false);
            });
        }
        this.dialog = true;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetData();
        this.dialog = false;
      } else {
        this.loadDataFromServer();
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (
        this.actionType &&
        this.$store.getters.getMAILTEMPLATESIsCustomer &&
        this.originalTemplateName === this.formData.name
      ) {
        Swal.fire({
          title: "Warning",
          text: "Please rename template to save as new template",
          icon: "error",
          showConfirmButton: true,
          timer: 10000,
        });
        return;
      }
      this.pageLoader(true);
      let actionUrl = "store";
      let sendData = {
        name: this.formData.name,
        mail: this.formData.mail,
      };

      if (!this.$store.getters.getMAILTEMPLATESIsCustomer) {
        sendData.type = this.formData.type;
        if (this.formData.type === 3) {
          sendData.customer_id = this.formData.customer_id;
        }

        if (this.actionType) {
          actionUrl = "update";
          sendData.id = this.$store.getters.getItemForAction.id;
        }
      }

      ApiService.post(`/settings/mailTemplate/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetData() {
      this.originalTemplateName = null;
      this.formData = { name: null, mail: null, type: null, customer_id: null };
    },
  },
  computed: {
    isDataLoaded() {
      return !!this.serverData;
    },
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    headerText: function () {
      if (this.actionType) return "Edit Mail Template";
      else return "Add New Mail Template";
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    typeErrors: function () {
      return this.handleFormValidation("type");
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id");
    },
  },
};
</script>
